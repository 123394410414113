import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import { notification, Modal, Empty, Pagination } from "antd";
import orderService from "../../services/orderService";
import authService from "../../services/authService";

import EmptyBox from "../../assets/images/empty-box.png";
import { TextField } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import { error } from "jquery";

//Refactor into baseTable and Listblah
class BaseAnt extends Component {
  state = {
    data: {},
    styles: {},
    errors: {},
    openDisableUser: false,
    pageConfig: {
      pageIndex: 1,
      pageSize: 10,
    },
    visible: false,
    confirmLoading: false,
    quantityDelivered: "",
    errorQuantity: false,
    errorMessage: "",
  };

  handleModalAction = (e) => {
    this.submitModalAction();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      openAddUserOption: false,
    });
    if (this.state.event === "compl") {
      this.setState({
        quantityDelivered: "",
        errorQuantity: false,
        errorMessage: "",
      });
    }
  };

  handleApproved = (e, orderItem) => {
    e.preventDefault();
    this.setState({
      visible: true,
      event: "appr",
      currentIteemId: orderItem,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;

    if (value === "" || Number(value) > 0) {
      // Valid input
      this.setState({
        quantityDelivered: value,
        errorQuantity: false,
        errorMessage: "",
      });
    } else {
      // Invalid input
      this.setState({
        errorQuantity: true,
        errorMessage: "Quantity must be greater than zero",
      });
    }
  };

  handleCompleteSchedule = (e, orderItem) => {
    e.preventDefault();
    this.setState({
      visible: true,
      event: "compl",
      currentIteemId: orderItem,
    });
  };

  handleDisableUser = async (userId, activate) => {
    this.setState({ loading: true });
    try {
      const { code, description } = await authService.disableVendorUser({
        userId,
        activate,
      });
      if (code >= 0) {
        this.openNotificationWithIcon("success", "Success", description);
        await this.loadData(this.state.pageConfig);
      } else this.openNotificationWithIcon("error", "Error", description);
    } catch (error) {
      this.openNotificationWithIcon("error", "Error", JSON.stringify(error));
    }
    this.setState({ loading: false });
    await this.loadData(this.state.pageConfig);
  };

  renderTableRowCount(pageIndex, pageSize, totalItems) {
    const totalPages = Math.ceil(totalItems / pageSize);
    return (
      <div className="col-lg-12 isw-valign--middle">
        {totalPages !== 0 ? (
          <p className="table-items-count mb-0">
            {" "}
            List (showing page {pageIndex}-{totalPages} of {totalItems}{" "}
            record(s)){" "}
          </p>
        ) : (
          <p></p>
        )}
      </div>
    );
  }

  renderPagination(total, pageIndex, pageSize, onPageChange) {
    const itemRender = (current, type, originalElement) => {
      if (type === "prev") {
        return <span>Previous</span>;
      }
      if (type === "next") {
        return <span>Next</span>;
      }
      return originalElement;
    };

    return (
      <div className="row">
        <div className="col-lg-12 col-sm-12 pr-5">
          <nav
            aria-label="pagination"
            className="float-right report-pagination"
          >
            <Pagination
              total={Math.ceil(total / pageSize) * 10 || 0}
              onChange={(e) => onPageChange(e)}
              hideOnSinglePage
              current={pageIndex}
              itemRender={itemRender}
            />
          </nav>
        </div>
      </div>
    );
  }

  renderGenericModal(
    title,
    visible,
    modalContent,
    onOK,
    onCancel,
    okText,
    cancelText,
    showFooter = true
  ) {
    return (
      <Modal
        visible={visible}
        title={title}
        onOk={this.handleOk}
        onCancel={onCancel}
        footer={
          showFooter
            ? [
                <Button key="back" variant="contained" onClick={onCancel}>
                  {cancelText}
                </Button>,
                <Button
                  color="primary"
                  variant="contained"
                  loading={this.props.isLoading}
                  onClick={onOK}
                >
                  <span key={title}> {okText}</span>
                </Button>,
              ]
            : []
        }
      >
        {modalContent}
      </Modal>
    );
  }

  handleDeclined = (e, item) => {
    e.preventDefault();
    this.setState({
      visible: true,
      event: "dec",
      currentIteemId: item,
    });
  };

  handlePageChange = (currentPage) => {
    const { pageConfig } = { ...this.state };
    if (currentPage !== pageConfig.pageIndex) {
      pageConfig.pageIndex = currentPage;
      this.props.onDataLoad(pageConfig);
      this.setState({ pageConfig });
    }
  };

  getApprovalValue = () => {
    if (authService.isVendorAdmin()) return 3;
    if (authService.isVendorLogistics()) return 6;
    return 3;
  };

  getOrderValue = () => {
    if (authService.isVendorAdmin()) return 2;
    if (authService.isVendorLogistics()) return 3;
    return 2;
  };

  //need to refactor the modal options
  submitModalAction = async () => {
    this.setState({ loading: true, visible: false });
    const status =
      this.state.event === "appr" || this.state.event === "compl"
        ? this.getApprovalValue()
        : 5;
    const data = {
      ids: [this.state.currentIteemId],
      status: status,
    };
    const dataTwo = {
      deliveryId: this.state.currentIteemId,
      status: status,
      quantityDelivered: +this.state.quantityDelivered,
    };
    const response =
      this.state.event === "compl"
        ? await orderService
            .completeOrder(dataTwo)
            .then((res) => {
              if (res.code === 2) {
                this.openNotificationWithIcon("error", res.description);
              }
              return res;
            })
            .catch((error) => {
              this.openNotificationWithIcon(
                "error",
                "Complete Order Approval Error",
                error.error_description
              );
            })
        : await orderService.approveOrder(data).catch((error) => {
            this.openNotificationWithIcon(
              "error",
              "Order Approval Error",
              error.error_description
            );
          });

    if (response != null) {
      console.log("response", response);
      const { description, code } = response;
      if (code === 0 || code === 1) {
        this.openNotificationWithIcon("success", "Success", description);
        this.setState({ loading: false, visible: false });
        await this.loadData(this.state.pageConfig);
      }
      if (this.state.event === "compl") {
        this.props.history.push("/vendor/pendingdelivery");
      } else {
        this.props.history.push("/vendor/orders");
      }
    }

    this.setState({ loading: false });
  };

  showPropsConfirm = () => {
    Modal.confirm({
      title: "Are you sure delete this task?",
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      okButtonProps: {
        disabled: true,
      },
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  renderModal(handleModalAction) {
    const { quantityDelivered, errorQuantity, errorMessage, event } =
      this.state;
    return (
      <Modal
        title="Are you sure you want to continue ?"
        visible={this.state.visible}
        onOk={this.handleModalAction}
        closable={false}
        confirmLoading={this.state.confirmLoading}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" variant="contained" onClick={this.handleCancel}>
            Cancel
          </Button>,

          event === "compl" ? (
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleModalAction}
              disabled={
                errorQuantity ||
                quantityDelivered === "" ||
                quantityDelivered === 0 ||
                quantityDelivered === undefined
              }
            >
              <span> Continue</span>
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleModalAction}
            >
              <span> Continue</span>
            </Button>
          ),
        ]}
      >
        <p>Kindly note that modifications to this data can't be reversed</p>
        {event === "compl" && (
          <div>
            <TextField
              label="Enter Quantity Delivered"
              name="quantityDelivered"
              id="quantityDelivered"
              style={{ width: "100%" }}
              margin="dense"
              variant="filled"
              value={quantityDelivered}
              onChange={this.handleInputChange}
              type="number"
              error={errorQuantity} // Controls the error state
              helperText={errorMessage} // Displays the error message
            />
          </div>
        )}
      </Modal>
    );
  }

  renderEmptyPanel(message) {
    const { loading } = this.state;
    return !loading ? (
      <div className="isw-valign--middle">
        <Empty
          image={EmptyBox}
          imagestyle={{
            height: 200,
          }}
          description={<span>{message}</span>}
        />
      </div>
    ) : (
      ""
    );
  }

  openNotificationWithIcon(type, title, message) {
    if (type === "error")
      notification.error({
        message: title,
        description: message,
        style: { zIndex: "1200" },
      });
    else
      notification.success({
        message: title,
        description: message,
        style: { zIndex: "1200" },
      });

    /* function A(x,y,z){
        
      } */
  }
}

export default BaseAnt;
